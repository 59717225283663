// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {

  //font-global
  --ion-font-xs: 12px;
  --ion-font-sm: 14px;
  --ion-font-small: 16px;
  --ion-font-md: 18px;
  --ion-font-lg: 20px;
  --ion-font-xl: 24px;


  --title-font: normal normal bold 16px/20px Helvetica Neue,sans-serif;
  --ion-font1: normal normal bold 24px/28px Helvetica Neue,sans-serif; //20
  --ion-font2: normal normal 300 18px/28px Helvetica Neue,sans-serif; //16
  --ion-font3: normal normal 500 16px/28px Helvetica Neue,sans-serif;
  --ion-font4: normal normal normal 18px/28px Helvetica Neue,sans-serif;
  --ion-font5: normal normal normal 20px/28px Helvetica Neue,sans-serif;
  --ion-font6: normal normal bold 14px/17px Helvetica Neue,sans-serif;
  --ion-font7: normal normal bold 14px/19px Helvetica Neue,sans-serif;
  --ion-font8: normal normal normal 14px/18px Helvetica Neue,sans-serif;
  --ion-font9: normal normal 600 16px/28px Helvetica Neue,sans-serif;
  --ion-font10: normal normal normal 14px/28px Helvetica Neue,sans-serif;
  --ion-font11: normal normal normal 16px/18px Helvetica Neue,sans-serif;


  --footer-font: normal normal normal 14px/28px Helvetica Neue,sans-serif;
  --ion-chip-border: #ababab;
  --ion-hover-color: #da291c;
}

// @media (prefers-color-scheme: dark) {
/*
   * Dark Colors
   * -------------------------------------------
   */

body[color-theme="light-dark"] {
  //dark mode colors added
  --ion-color-toolbar: #ffffff;
  --ion-color-border: #9F9FA434;
  --ion-color-shadow: #00000029;
  --ion-color-footer: #2F2D2D;
  --ion-nav-color: #2F2D2D;
  --ion-nav-border: #9F9FA433;
  --ion-color-filler: #2F2D2D;
  --ion-navBorder-color: #BFBFBF64;
  --ion-checkbox-color: #656565;

  --ion-inkbar-color: #FFFFFF;
  --ion-tabtext-color: #797979;
  --ion-segment-color: #9F9FA4;
  --ion-segmentborder-color: #9F9FA4;
  --ion-footerright-color: #ffffff;

  --ion-segment-checked-color: #000000;
  --ion-table-bg-color: #111111;
  --ion-table-boxshadow-color: #9696965C;
  --ion-checkBorder-color: none;
  --ion-table-color: #111111;
  --ion-oddrow-color: #272727;
  --ion-paginatorbg-color: #2F2D2D;
  --ion-activeCheckbox-color: #424242;

  --ion-item-background: #424242;
  --ion-card-background: #424242;
  --ion-container-background: #838383;
  --ion-input-border: #D6D6D6;
  --ion-hover-color: #D6D6D6;
  --ion-tabletext-color: #FFFFFF;
  --ion-tabletextt-color: #FFFFFF;
  --ion-thborder-color: #9F9FA439;
  --ion-paginator-color: #9F9FA4;
  --ion-tooltip-color: #ffffff; //added
  --ion-tooltiptext-color: #000;
  --ion-tooltip-background: #fff;
  --ion-placeholder-color: #424242;
  --ion-toggle-color: #fff;
  --ion-toggle-background: #838383;
  --ion-toggle-disabled: #272727;
  --ion-placeholder-color: #FFFFFF;
  --ion-indicator-color: #fff;
  --ion-login-background: #111111;
  --ion-drag-background: #838383;
  --ion-card-header-background: #272727;
  --card-content-bg: #424242;
  --card-content-bg1: #838383;

  --ion-color-disabled: #A3A3A3;
  --ion-input-border-disabled: #564c4c;
  --ion-tabcolor-mobile: #fff;
  --ion-pbar-color: #9F9FA4;
  --ion-color-primary-contrast: #000;
  --ion-tab-bar-background:#2F2D2D;
  --ion-drag-shadow:#9696965c;
  --filter-img: url("../assets/icon/Icon material-filter-list-white.png");
  --ion-fileImg: url("../assets/icon/xls-light19865@2x.png");
  --ion-theme-filter:url("../assets/icon/format_color_fill_black_24dp@2x.png");
  --ion-language-filter:url("../assets/icon/language-white@2x.png");


}

/*
   * iOS Dark Theme
   * -------------------------------------------
   */

.ios body[color-theme="light-dark"] {
  --ion-background-color: #2F2D2D 0% 0% no-repeat padding-box;
  --ion-background-color-rgb: 0, 0, 0;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-item-background: #424242;
  --ion-card-background: #424242;
  --ion-container-background: #838383;
  --ion-input-border: #D6D6D6;
  --ion-hover-color: #D6D6D6;
  --ion-toggle-color: #fff;
  --ion-toggle-background: #838383;
  --ion-toggle-disabled: #272727;
  --ion-tooltip-color: #ffffff;
  --ion-tooltiptext-color: #000;
  --ion-tooltip-background: #fff;
  --ion-indicator-color: #fff;
  --ion-login-background: #111111;
  --ion-drag-background: #838383;

  --ion-toolbar-background: #2F2D2D 0% 0% no-repeat padding-box;

  --ion-placeholder-color: #FFFFFF;
}

.ios ion-modal {
  --ion-background-color: var(--ion-color-step-100);
  --ion-toolbar-background: var(--ion-color-step-150);
  --ion-toolbar-border-color: var(--ion-color-step-250);
}


/*
   * Material Design Dark Theme
   * -------------------------------------------
   */

.md body[color-theme="light-dark"] {

  --ion-background-color: #2F2D2D 0% 0% no-repeat padding-box;
  --ion-background-color-rgb: 18, 18, 18;
  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;
  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #424242;
  --ion-card-background: #424242;
  --ion-container-background: #838383;
  --ion-input-border: #D6D6D6;
  --ion-hover-color: #D6D6D6;
  --ion-toggle-color: #fff;
  --ion-toggle-background: #838383;
  --ion-toggle-disabled: #272727;
  --ion-toolbar-background: #2F2D2D 0% 0% no-repeat padding-box;
  --ion-tooltip-color: #ffffff;
  --ion-tooltiptext-color: #000;
  --ion-tooltip-background: #fff;
  --ion-indicator-color: #fff;
  --ion-login-background: #111111;
  --ion-drag-background: #838383;

  --ion-placeholder-color: #FFFFFF;

}

body[color-theme="red"] {
  //light mode colors
  --ion-color-toolbar: #DA291C;
  --ion-color-footer: #000000;
  --ion-footer-text-color: #ffffff;
  --ion-color-filler: #DA291C;
  --ion-background-color: #ffffff;

  --ion-activeCheckbox-color: #DA291C;
  --ion-checkbox-color: #EBEBEB;

  --ion-checkBorder-color: #DA291C;
  --ion-inkbar-color: #DA291C;
  --ion-nav-color: #ffffff;
  --ion-nav-border: #D6D6D6;
  --ion-segment-checked-color: #ffffff;
  --ion-segmentborder-color: #000000;
  --ion-segment-color: #000000;
  --ion-footerright-color: #ffffff;


  --ion-table-color: #FFFFFF;
  --ion-oddrow-color: #FAFAFA;
  --ion-tabletext-color: #000000;
  --ion-tabletextt-color: #212121;
  --ion-thborder-color: #D6D6D6;
  --ion-paginator-color: #212121;
  --ion-card-background: #FFFFFF;
  --ion-tooltip-color: #da291c;
  --ion-tooltiptext-color: #fff;
  --ion-tooltip-background: #000;
  --ion-toolbar-background: #ffffff 0% 0% no-repeat padding-box;
  --ion-boxShadow-mobile: #cab7b7;
  --ion-tabtext-color: #797979;


  --ion-placeholder-color: #424242;
  --ion-input-border: #D6D6D6;
  --ion-container-background: #fff;
  --ion-table-boxshadow-color: #9696965C;
  --ion-toggle-color: #da291c;
  --ion-toggle-background: #838383;
  --ion-toggle-disabled: #D6D6D6;
  --ion-indicator-color: #DA291C;
  --ion-login-background: #DA291C;
  --ion-drag-background: #FFFAFA;

  --ion-card-header-background: #F7F7F7;
  --card-content-bg: #faefef;
  --card-content-bg1: #FFF5F5;

  --ion-color-disabled: black;
  --ion-input-border-disabled: #D6D6D6;
  --ion-tabcolor-mobile: #da291c;
  --ion-pbar-color: #da2916;
  --ion-color-primary-contrast: #fff;
  --background-img: url("../assets/icon/background-welcome-page@2x.png");
  --filter-img: url("../assets/icon/Icon material-filter-list@2x.png");
--ion-tab-bar-background:#fff;
 --ion-drag-shadow: #d6d6d6;
 --ion-fileImg: url("../assets/icon/Group 19865.png");
 --ion-theme-filter:url("../assets/icon/format_color_fill_white_24dp@2x.png");
 --ion-language-filter:url("../assets/icon/language@2x.png");

}

.ios body[color-theme="red"] {
  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 0, 0, 0;

  --ion-text-color: #000000;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-item-background: #FFFFFF;
  --ion-card-background: #FFFFFF;
  --ion-tooltip-color: #da291c;
  --ion-tooltiptext-color: #fff;
  --ion-tooltip-background: #000;
  --ion-input-border: #D6D6D6;
  --ion-placeholder-color: #424242;
  --ion-container-background: #fff;
  --ion-toggle-color: #da291c;
  --ion-toggle-background: #838383;
  --ion-table-boxshadow-color: #9696965C;
  --ion-indicator-color: #DA291C;
  --ion-login-background: #DA291C;
  --ion-drag-background: #FFFAFA;
}

.md body[color-theme="red"] {
  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 0, 0, 0;
  --ion-text-color: #000000;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;

  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #000;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #FFFFFF;
  --ion-card-background: #FFFFFF;
  --ion-tooltip-color: #da291c;
  --ion-tooltiptext-color: #fff;
  --ion-tooltip-background: #000;
  --ion-input-border: #D6D6D6;
  --ion-placeholder-color: #424242; //placeholder color
  --ion-container-background: #fff;
  --ion-toggle-color: #da291c;
  --ion-toggle-background: #838383;
  --ion-indicator-color: #DA291C;
  --ion-login-background: #DA291C;
  --ion-drag-background: #FFFAFA;
}


body[color-theme="light-green"] {
  --ion-color-toolbar: #ffffff; //text
  --ion-color-footer: #006553;
  --ion-color-filler: #006553; // theme change image color

  --ion-activeCheckbox-color: #006553; //1 level nav cbox color
  --ion-checkbox-color: #EBEBEB;
  --ion-checkBorder-color: #006553;
  --ion-inkbar-color: #006553;
  --ion-nav-color: #F8FFFC;
  --ion-nav-border: #D6D6D6;
  --ion-segment-checked-color: #ffffff; //text
  --ion-segmentborder-color: #006553;
  --ion-segment-color: #000000; //unchecked text
  --ion-tabtext-color: #797979;
  --ion-footerright-color: #ffffff;

  --ion-table-boxshadow-color: #9696965C;
  --ion-table-color: #FFFFFF;
  --ion-oddrow-color: #FAFAFA;
  --ion-tabletext-color: #000000;
  --ion-tabletextt-color: #212121;
  --ion-thborder-color: #D6D6D6;
  --ion-paginator-color: #212121;
  --ion-boxShadow-mobile: #cab7b7;


  --ion-input-border: #D6D6D6;
  --ion-hover-color: #006553;
  --ion-placeholder-color: #424242;
  --ion-toolbar-background: #006553 0% 0% no-repeat padding-box;
  --ion-card-background: #FFFFFF;
  --ion-item-background: #FFFFFF;
  --ion-toggle-color: #006553;
  --ion-toggle-background: #838383;
  --ion-toggle-disabled: #D6D6D6;
  --ion-tooltip-color: #006553;
  --ion-tooltiptext-color: #fff;
  --ion-tooltip-background: #000;
  --ion-indicator-color: #006553;
  --ion-login-background: #006553;
  --ion-card-header-background: #F8FFFC;
  --card-content-bg: #e4f8f2;
  --card-content-bg1: #e4f8f2;

  --ion-color-disabled: #424242;
  --ion-input-border-disabled: #D6D6D6;
  --ion-tabcolor-mobile: #006553;
  --ion-pbar-color: #006553;
  --ion-color-primary-contrast: #fff;
  --background-img: url("../assets/icon/welcome-background-green@2x.png");
  --background-content: url("../assets/icon/light-green-bg2@2x.png");
  --filter-img: url("../assets/icon/Icon material-filter-list-green.png");
  --ion-tab-bar-background:#fff;
  --ion-drag-shadow: #d6d6d6;
  --ion-fileImg: url("../assets/icon/Group 19865.png");
  --ion-theme-filter:url("../assets/icon/format_color_fill_white_24dp@2x.png");
  --ion-language-filter:url("../assets/icon/language@2x.png");

}

/*
     * iOS Dark Theme
     * -------------------------------------------
     */

.ios body[color-theme="light-green"] {
  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 0, 0, 0;

  --ion-text-color: #000000;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;
  --ion-nav-color: #F8FFFC;

  --ion-item-background: #FFFFFF;
  --ion-card-background: #FFFFFF;
  --ion-input-border: #D6D6D6;
  --ion-hover-color: #006553;
  --ion-tooltip-color: #006553;
  --ion-tooltiptext-color: #fff;
  --ion-tooltip-background: #000;
  --ion-placeholder-color: #424242;
  --ion-toggle-color: #006553;
  --ion-toggle-background: #838383;
  --ion-indicator-color: #006553;
  --ion-login-background: #006553;
}

.ios ion-modal {
  --ion-background-color: var(--ion-color-step-100);
  --ion-toolbar-background: var(--ion-color-step-150);
  --ion-toolbar-border-color: var(--ion-color-step-250);
}


/*
     * Material Design Dark Theme
     * -------------------------------------------
     */

.md body[color-theme="light-green"] {

  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 0, 0, 0;
  --ion-text-color: #000000;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #000;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;
  --ion-nav-color: #F8FFFC;
  --ion-indicator-color: #006553;
  --ion-login-background: #006553;
  --ion-item-background: #FFFFFF;
  --ion-card-background: #FFFFFF;
  --ion-input-border: #D6D6D6;
  --ion-hover-color: #006553;
  --ion-tooltip-color: #006553;
  --ion-tooltiptext-color: #fff;
  --ion-tooltip-background: #000;
  --ion-placeholder-color: #424242;
  --ion-toggle-color: #006553;
  --ion-toggle-background: #838383;

}

body[color-theme="geometric"] {

  --ion-color-toolbar: #ffffff;
  --ion-color-footer: #000000;
  --ion-color-filler: #000000;

  --ion-activeCheckbox-color: #60094C;
  --ion-checkbox-color: #EBEBEB;
  --ion-checkBorder-color: #60094C;
  --ion-inkbar-color: #60094C;
  --ion-nav-color: #FFF4F4;
  --ion-nav-border: #D6D6D6;
  --ion-segment-checked-color: #ffffff;
  --ion-segmentborder-color: #000000;
  --ion-segment-color: #000000;
  --ion-tabtext-color: #797979;
  --ion-footerright-color: #ffffff;

  --ion-table-boxshadow-color: #9696965C;
  --ion-table-color: #FFFFFF;
  --ion-oddrow-color: #FAFAFA;
  --ion-tabletext-color: #000000;
  --ion-tabletextt-color: #212121;
  --ion-thborder-color: #D6D6D6;
  --ion-paginator-color: #212121;
  --ion-boxShadow-mobile: #cab7b7;


  --ion-toolbar-background: #000000 0% 0% no-repeat padding-box;
  --ion-tooltip-color: #da291c;
  --ion-tooltiptext-color: #fff;
  --ion-tooltip-background: #000;
  --ion-placeholder-color: #424242;
  --ion-toggle-color: #60094C;
  --ion-toggle-background: #838383;
  --ion-toggle-disabled: #D6D6D6;
  --ion-card-background: #FFFFFF;
  --ion-item-background: #FFFFFF;
  --ion-input-border: #D6D6D6;
  --ion-hover-color: #60094C;
  --ion-indicator-color: #60094C;
  --ion-login-background: #60094C;
  --ion-card-header-background: #FFFFFF;
  --card-content-bg: #faefef;
  --card-content-bg1: #FFF4F4;
  --ion-container-background: #fff;

  --ion-color-disabled: #424242;
  --ion-input-border-disabled: #D6D6D6;
  --ion-tabcolor-mobile: #60094C;
  --ion-pbar-color: #60094C;
  --ion-color-primary-contrast: #fff;
  --background-img: url("../assets/icon/geometricBg.png");
  --background-content: url("../assets/icon/geometricBg.png");
  --ion-tab-bar-background:#fff;
  --ion-drag-shadow: #d6d6d6;
  --filter-img: url("../assets/icon/filterimagepurple.png");
  --ion-fileImg: url("../assets/icon/Group 19865.png");
  --ion-theme-filter:url("../assets/icon/format_color_fill_white_24dp@2x.png");
  --ion-language-filter:url("../assets/icon/language@2x.png");

}

/*
     * iOS Dark Theme
     * -------------------------------------------
     */

.ios body[color-theme="geometric"] {
  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 0, 0, 0;

  --ion-text-color: #000000;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-item-background: #FFFFFF;
  --ion-card-background: #FFFFFF;
  --ion-input-border: #D6D6D6;
  --ion-hover-color: #60094C;
  --ion-tooltip-color: #da291c;
  --ion-tooltiptext-color: #fff;
  --ion-tooltip-background: #000;
  --ion-placeholder-color: #424242;
  --ion-indicator-color: #60094C;
  --ion-login-background: #60094C;

}

.ios ion-modal {
  --ion-background-color: var(--ion-color-step-100);
  --ion-toolbar-background: var(--ion-color-step-150);
  --ion-toolbar-border-color: var(--ion-color-step-250);
}


/*
     * Material Design Dark Theme
     * -------------------------------------------
     */

.md body[color-theme="geometric"] {

  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 0, 0, 0;
  --ion-text-color: #000000;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #000;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #FFFFFF;
  --ion-card-background: #FFFFFF;
  --ion-input-border: #D6D6D6;
  --ion-hover-color: #60094C;
  --ion-tooltip-color: #da291c;
  --ion-tooltiptext-color: #fff;
  --ion-tooltip-background: #000;
  --ion-placeholder-color: #424242;
  --ion-indicator-color: #60094C;
  --ion-login-background: #60094C;
}

body[color-theme="light-red"] {
  //light mode colors
  --ion-color-toolbar: #ffffff;
  --ion-color-footer: #811717;
  --ion-color-filler: #811717;
  --ion-activeCheckbox-color: #811717;
  --ion-checkbox-color: #EBEBEB;
  --ion-checkBorder-color: #811717;
  --ion-inkbar-color: #811717;
  --ion-nav-color: #FBE4E4;
  --ion-nav-border: #D6D6D6;
  --ion-segment-checked-color: #ffffff;
  --ion-segmentborder-color: #000000;
  --ion-segment-color: #000000;
  --ion-tabtext-color: #797979;
  --ion-footerright-color: #ffffff;
  --ion-boxShadow-mobile: #cab7b7;

  --ion-table-boxshadow-color: #9696965C;
  --ion-table-color: #FFFFFF;
  --ion-oddrow-color: #FAFAFA;
  --ion-tabletext-color: #000000;
  --ion-tabletextt-color: #212121;
  --ion-thborder-color: #D6D6D6;
  --ion-paginator-color: #212121;
  --ion-tooltip-color: #da291c;
  --ion-tooltiptext-color: #fff;
  --ion-tooltip-background: #000;
  --ion-toolbar-background: #811717 0% 0% no-repeat padding-box;
  --ion-placeholder-color: #424242; //placeholder color
  --ion-toggle-color: #811717;
  --ion-toggle-background: #838383;
  --ion-toggle-disabled: #D6D6D6;
  --ion-card-background: #FFFFFF;
  --ion-item-background: #FFFFFF;
  --ion-input-border: #D6D6D6;
  --ion-hover-color: #811717;
  --ion-indicator-color: #811717;
  --ion-login-background: #811717;
  --ion-card-header-background: #FFFFFF;
  --card-content-bg: #faefef;
  --card-content-bg1: #F9DCDC;

  --ion-color-disabled: #424242;
  --ion-input-border-disabled: #D6D6D6;
  --ion-tabcolor-mobile: #811717;
  --ion-pbar-color: #811717;
  --ion-color-primary-contrast: #fff;
  --background-img: url("../assets/icon/light-rednew.png");
  --background-content: url("../assets/icon/light-rednew.png");
  --ion-tab-bar-background:#fff;
  --ion-drag-shadow: #d6d6d6;
  --filter-img: url("../assets/icon/filter-list-light-red@2x.png");
  --ion-fileImg: url("../assets/icon/Group 19865.png");
  --ion-theme-filter:url("../assets/icon/format_color_fill_white_24dp@2x.png");
  --ion-language-filter:url("../assets/icon/language@2x.png");

}

/*
     * iOS Dark Theme
     * -------------------------------------------
     */

.ios body[color-theme="light-red"] {
  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 0, 0, 0;

  --ion-text-color: #000000;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-item-background: #FFFFFF;
  --ion-input-border: #D6D6D6;
  --ion-hover-color: #811717;
  --ion-card-background: #FFFFFF;
  --ion-tooltip-color: #da291c;
  --ion-tooltiptext-color: #fff;
  --ion-tooltip-background: #000;
  --ion-placeholder-color: #424242;
  --ion-toggle-color: #811717;
  --ion-toggle-background: #838383;
  --ion-indicator-color: #811717;
  --ion-login-background: #811717;

}

.ios ion-modal {
  --ion-background-color: var(--ion-color-step-100);
  --ion-toolbar-background: var(--ion-color-step-150);
  --ion-toolbar-border-color: var(--ion-color-step-250);
}


/*
     * Material Design Dark Theme
     * -------------------------------------------
     */

.md body[color-theme="light-red"] {

  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 0, 0, 0;
  --ion-text-color: #000000;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #000;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #FFFFFF;
  --ion-input-border: #D6D6D6;
  --ion-hover-color: #811717;
  --ion-card-background: #FFFFFF;
  --ion-tooltip-color: #da291c;
  --ion-tooltiptext-color: #fff;
  --ion-tooltip-background: #000;
  --ion-placeholder-color: #424242;
  --ion-toggle-color: #811717;
  --ion-toggle-background: #838383;
  --ion-indicator-color: #811717;
  --ion-login-background: #811717;
}

body[color-theme="dark-black"] {
  --ion-color-toolbar: #ffffff;
  --ion-color-border: #9F9FA434;
  --ion-color-shadow: #00000029;
  --ion-color-footer: #111111;
  --ion-nav-color: #111111;
  --ion-nav-border: #9F9FA433;
  --ion-color-filler: #111111;
  --ion-navBorder-color: #BFBFBF64;
  --ion-checkbox-color: #656565;
  --ion-inkbar-color: #FFFFFF;
  --ion-tabtext-color: #797979;
  --ion-segment-color: #9F9FA4;
  --ion-segmentborder-color: #9F9FA4;

  --ion-segment-checked-color: #000000;
  --ion-table-bg-color: #111111;
  --ion-table-boxshadow-color: #9696965C;
  --ion-checkBorder-color: none;
  --ion-table-color: #424242;
  --ion-oddrow-color: #272727;
  --ion-paginatorbg-color: #111111;
  --ion-activeCheckbox-color: #424242;

  --ion-tabletext-color: #FFFFFF;
  --ion-tabletextt-color: #FFFFFF;
  --ion-thborder-color: #9F9FA439;
  --ion-paginator-color: #9F9FA4;
  --ion-toolbar-background: #111111 0% 0% no-repeat padding-box;
  --ion-tooltip-color: #ffffff;
  --ion-tooltiptext-color: #000;
  --ion-tooltip-background: #fff;
  --ion-placeholder-color: #ffffff; //placeholder color
  --ion-container-background: #838383;
  --ion-overlay-background-color: #838383;
  --ion-hover-color: #ffffff;
  --ion-toggle-color: #831515;
  --ion-toggle-background: #272727;
  --ion-toggle-disabled: #272727;
  --ion-indicator-color: #fff;
  --ion-login-background: #272727;
  --ion-drag-background: #838383;
  --card-content-bg: #272727;
  --card-content-bg1: #838383;

  --ion-card-header-background: #424242;
  --ion-color-disabled: #A3A3A3;
  --ion-input-border-disabled: #564c4c;
  --ion-input-border:#ffffff;
  --ion-tabcolor-mobile: #fff;
  --ion-pbar-color: #9F9FA4;
  --ion-color-primary-contrast: #000;
  --ion-tab-bar-background:#111111;
  --ion-drag-shadow:#9696965c;
  --filter-img: url("../assets/icon/Icon material-filter-list-white.png");
  --ion-fileImg: url("../assets/icon/xls-light19865@2x.png");
  --ion-theme-filter:url("../assets/icon/format_color_fill_black_24dp@2x.png");
  --ion-language-filter:url("../assets/icon/language-white@2x.png");

}

.ios body[color-theme="dark-black"] {
  --ion-background-color: #111111 0% 0% no-repeat padding-box;
  --ion-background-color-rgb: 0, 0, 0;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-item-background: #000000;

  --ion-card-background: #1c1c1d;
  --ion-tooltip-color: #ffffff; //added
  --ion-tooltiptext-color: #000;
  --ion-tooltip-background: #fff;
  --ion-tooltip-background: #000;
  --ion-placeholder-color: #ffffff; //placeholder color
  --ion-container-background: #838383;
  --ion-overlay-background-color: #838383;
  --ion-hover-color: #ffffff;
  --ion-toggle-color: #e2e2e2;
  --ion-toggle-background: #272727;
  --ion-indicator-color: #fff;
  --ion-login-background: #272727;
  --ion-drag-background: #838383;

}

.ios ion-modal {
  --ion-background-color: var(--ion-color-step-100);
  --ion-toolbar-background: var(--ion-color-step-150);
  --ion-toolbar-border-color: var(--ion-color-step-250);
}


/*
     * Material Design Dark Theme
     * -------------------------------------------
     */

.md body[color-theme="dark-black"] {

  --ion-background-color: #111111 0% 0% no-repeat padding-box;
  --ion-background-color-rgb: 18, 18, 18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-indicator-color: #fff;
  --ion-login-background: #272727;
  --ion-item-background: #1e1e1e;
  --ion-card-background: #1e1e1e;
  --ion-tooltip-color: #ffffff;
  --ion-tooltiptext-color: #000;
  --ion-tooltip-background: #fff;
  --ion-placeholder-color: #ffffff;
  --ion-container-background: #838383;
  --ion-overlay-background-color: #838383;
  --ion-hover-color: #ffffff;
  --ion-toggle-color: #e2e2e2;
  --ion-toggle-background: #272727;
  --ion-toggle-disabled: #272727;
  --ion-drag-background: #838383;
}



body[color-theme="dark-green"] {
  --ion-color-toolbar: #ffffff;
  --ion-color-border: #9F9FA434;
  --ion-color-shadow: #00000029;
  --ion-color-footer: #001914;
  --ion-color-filler: #001914;

  --ion-nav-color: #00120E;
  --ion-nav-border: #9F9FA433;
  --ion-navBorder-color: #BFBFBF64;
  --ion-checkbox-color: #022C1F;
  --ion-inkbar-color: #CFF5E9;
  --ion-tabtext-color: #797979;
  --ion-segment-color: #9F9FA4;
  --ion-segmentborder-color: #CFF5E9;
  --ion-segment-checked-color: #000000;
  --ion-checkBorder-color: #656565;
  --ion-activeCheckbox-color: #022C1F;

  --ion-table-bg-color: #111111;
  --ion-table-boxshadow-color: #9696965C;
  --ion-table-color: #001914;
  --ion-oddrow-color: #043626;
  --ion-tabletext-color: #FFFFFF;
  --ion-tabletextt-color: #FFFFFF;
  --ion-thborder-color: #9F9FA439;
  --ion-paginator-color: #9F9FA4;

  --ion-item-background: #001914;
  --ion-card-background: #001914;
  --ion-container-background: #043626;
  --ion-input-border: #CFF5E9;
  --ion-toolbar-background: #001914 0% 0% no-repeat padding-box;
  --ion-tooltip-color: #CFF5E9;
  --ion-tooltiptext-color: #000;
  --ion-tooltip-background: #fff;
  --ion-toggle-disabled: #00211A;
  --ion-toggle-color: #CFF5E9;
  --ion-toggle-background: #797979;
  --ion-placeholder-color: #FFFFFF;
  --ion-toggle-disabled: #838383;
  --ion-hover-color: #CFF5E9;
  --ion-indicator-color: #CFF5E9;
  --ion-login-background: #083929;
  --ion-drag-background: #043626;
  --ion-card-header-background: #001914;
  --card-content-bg: #043626;
  --card-content-bg1: #043626;

  --ion-color-disabled: #A3A3A3;
  --ion-input-border-disabled: #000000;
  --ion-tabcolor-mobile: #fff;
  --ion-pbar-color: #CFF5E9;
  --ion-color-primary-contrast: #000;
  --ion-tab-bar-background:#00120E;
  --ion-drag-shadow:#9696965c;
  --filter-img: url("../assets/icon/filter-list-green@2x.png");
  --ion-fileImg: url("../assets/icon/xls-light19865@2x.png");
  --ion-theme-filter:url("../assets/icon/format_color_fill_black_24dp@2x.png");
  --ion-language-filter:url("../assets/icon/language-white@2x.png");

}


.ios body[color-theme="dark-green"] {
  --ion-background-color: #00120E 0% 0% no-repeat padding-box;
  --ion-background-color-rgb: 0, 0, 0;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-item-background: #001914;
  --ion-card-background: #001914;
  --ion-container-background: #043626;
  --ion-input-border: #CFF5E9;
  --ion-tooltip-color: #CFF5E9;
  --ion-tooltiptext-color: #000;
  --ion-tooltip-background: #fff;
  --ion-toggle-disabled: #00211A;
  --ion-toggle-color: #CFF5E9;
  --ion-toggle-background: #797979;
  --ion-placeholder-color: #FFFFFF;
  --ion-hover-color: #CFF5E9;
  --ion-indicator-color: #CFF5E9;
  --ion-login-background: #083929;
  --ion-drag-background: #043626;

}

.ios ion-modal {
  --ion-background-color: var(--ion-color-step-100);
  --ion-toolbar-background: var(--ion-color-step-150);
  --ion-toolbar-border-color: var(--ion-color-step-250);
}


/*
     * Material Design Dark Theme
     * -------------------------------------------
     */

.md body[color-theme="dark-green"] {

  --ion-background-color: #00120E 0% 0% no-repeat padding-box;
  --ion-background-color-rgb: 18, 18, 18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #001914;
  --ion-card-background: #001914;
  --ion-container-background: #043626;
  --ion-input-border: #CFF5E9;
  --ion-tooltip-color: #CFF5E9;
  --ion-tooltiptext-color: #000;
  --ion-tooltip-background: #fff;
  --ion-toggle-disabled: #00211A;
  --ion-toggle-color: #CFF5E9;
  --ion-toggle-background: #797979;
  --ion-placeholder-color: #ffffff;
  --ion-hover-color: #CFF5E9;
  --ion-indicator-color: #CFF5E9;
  --ion-login-background: #083929;
  --ion-drag-background: #043626;
}


body[color-theme="purple"] {
  --ion-color-toolbar: #ffffff;
  --ion-color-footer: #110C2B;
  --ion-color-filler: #110C2B;
  --ion-color-border: #9F9FA434;

  --ion-color-shadow: #00000029;

  --ion-activeCheckbox-color: #C972DE;
  --ion-checkbox-color: #312E45;
  --ion-checkbox-color1: #312E45;

  --ion-checkBorder-color: #656565;
  --ion-inkbar-color: #C972DE;
  --ion-nav-color: #191431;
  --ion-nav-border: #9F9FA433;
  --ion-navBorder-color: #BFBFBF64;
  --ion-segment-color: #9F9FA4;
  --ion-segmentborder-color: #412C69;
  --ion-segment-checked-color: #FFFFFF;
  --ion-tabtext-color: #797979;

  --ion-table-bg-color: #111111;
  --ion-table-boxshadow-color: #9696965C;
  --ion-table-color: #241D42;
  --ion-oddrow-color: #412C69;
  --ion-tabletext-color: #FFFFFF;
  --ion-thborder-color: #9F9FA439;
  --ion-paginator-color: #9F9FA4;
  --ion-tooltip-color: #C972DE;
  --ion-tooltiptext-color: #000;
  --ion-tooltip-background: #fff;
  --ion-placeholder-color: #FFFFFF;
  --ion-input-border: #C972DE;

  --ion-toolbar-background: #110C2B 0% 0% no-repeat padding-box;
  --ion-toggle-disabled: #140D2F;
  --ion-toggle-color: #C972DE;
  --ion-toggle-background: #797979;
  --ion-container-background: #412C69;
  --ion-hover-color: #C972DE;
  --ion-card-background: #241D42;
  --ion-item-background: #241D42;
  --ion-indicator-color: #C972DE;
  --ion-login-background: #C972DE;
  --ion-drag-background: #412C69;

  --card-content-bg: #412C69;
  --card-content-bg1: #412C69;

  --ion-card-header-background: #241D42;
  --ion-color-disabled: #A3A3A3;
  --ion-input-border-disabled: #000000;
  --ion-tabcolor-mobile: #fff;
  --ion-pbar-color: #C972DE;
  --ion-color-primary-contrast: #000;
  --ion-tab-bar-background:#191431;
  --ion-drag-shadow:#9696965c;
  --filter-img: url("../assets/icon/icon\ material-filter-list-purple.png");
  --ion-fileImg: url("../assets/icon/xls-light19865@2x.png");
  --ion-theme-filter:url("../assets/icon/format_color_fill_black_24dp@2x.png");
  --ion-language-filter:url("../assets/icon/language-white@2x.png");

}


.ios body[color-theme="purple"] {
  --ion-background-color: #191431 0% 0% no-repeat padding-box;
  --ion-background-color-rgb: 0, 0, 0;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-input-border: #C972DE;

  --ion-item-background: #241D42;
  --ion-card-background: #241D42;
  --ion-tooltip-color: #C972DE;
  --ion-tooltiptext-color: #000;
  --ion-tooltip-background: #fff;
  --ion-toggle-disabled: #140D2F;
  --ion-toggle-color: #C972DE;
  --ion-toggle-background: #797979;
  --ion-placeholder-color: #FFFFFF;
  --ion-container-background: #412C69;
  --ion-hover-color: #C972DE;
  --ion-indicator-color: #C972DE;
  --ion-login-background: #C972DE;
  --ion-drag-background: #412C69;

}

.ios ion-modal {
  --ion-background-color: var(--ion-color-step-100);
  --ion-toolbar-background: var(--ion-color-step-150);
  --ion-toolbar-border-color: var(--ion-color-step-250);
}


/*
     * Material Design Dark Theme
     * -------------------------------------------
     */

.md body[color-theme="purple"] {

  --ion-background-color: #191431 0% 0% no-repeat padding-box;
  --ion-background-color-rgb: 18, 18, 18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-input-border: #C972DE;

  --ion-item-background: #241D42;
  --ion-card-background: #241D42;
  --ion-tooltip-color: #C972DE;
  --ion-tooltiptext-color: #000;
  --ion-tooltip-background: #fff;
  --ion-toggle-disabled: #140D2F;
  --ion-toggle-color: #C972DE;
  --ion-toggle-background: #797979;
  --ion-placeholder-color: #FFFFFF;
  --ion-container-background: #412C69;
  --ion-hover-color: #C972DE;
  --ion-indicator-color: #C972DE;
  --ion-login-background: #C972DE;
  --ion-drag-background: #412C69;
}
