/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@font-face {
    font-family: 'Helvetica Neue';
    src: url('./assets/fonts/HelveticaNeueCyr-Roman.ttf');
}

@font-face {
    font-family: 'HelveticaNeue-Light';
    src: url('./assets/fonts/HelveticaNeueCyr-Light.ttf');
}

@font-face {
    font-family: 'HelveticaNeue-Bold';
    src: url('./assets/fonts/HelveticaNeueCyr-Bold.ttf');
}

@font-face {
    font-family: 'Effra' ;
    src: url('./assets/fonts/Effra_Std_Bd.ttf');
}

ion-popover {
  &.my-custom-class {
       --height: 55%;
       --min-height: auto;
  }
}

ion-toast.success {
    --background: #8cdf93;
    --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
    --color: #254431;
  }
      
  ion-toast.success::part(message) {
    font-style: italic;
  }
  
  ion-toast.success::part(button) {
    border-left: 1px solid #d2d2d2;
    color: #030207;
    font-size: 15px;
  }
  
  ion-toast.error {
    --background: #eb8c8c;
    --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
    --color: #702929;
  }
      
  ion-toast.error::part(message) {
    font-style: italic;
  }
  
  ion-toast.error::part(button) {
    border-left: 1px solid #d2d2d2;
    color: #030207;
    font-size: 15px;
  }

  .back{
    --backdrop-opacity:0% !important;
    --box-shadow: 0.4px;
    }

  .alert{
    --min-width: 40vw;
  }

.alert-tappable.alert-radio {
  height: auto;
  contain: content;
}

// ion-modal.date-modal{
//   --width: fit-content;
//   --height:fit-content;
// }

.date-range-filter-modal{
  --width: fit-content;
  --height: fit-content;
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  white-space: normal !important;
}

// .select-popover .popover-content{
//   // contain: content;
//   --width: 100% !important; 
//   --max-width: 100% !important;


// }

// ::ng-deep  .popover-content{
//     width: 100% !important;
//   }

  ::ng-deep .popover-content::part(content){
    --max-width: unset !important;
    --width:max-content !important;
}

ion-popover ion-select-popover {
  ion-item.text-wrap ion-label {
    white-space: normal !important;
  }
}

.item.sc-ion-label-md-h, .item .sc-ion-label-md-h{
  white-space: normal !important;
}

ion-modal.date-modal{
  --width: fit-content;
  --height:fit-content;
}